<!-- About Widget -->
<div class="widget about-widget">
    <div class="logo mb-30">
        <img height="100px"  src="assets/img/logo-3.png" alt="img">
    </div>
    <p>
        Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi.
    </p>
</div>
<!-- Contact information Widget -->
<div class="widget contact-info-widget">
    <h5 class="widget-title">Contact Us</h5>
    <ul>
        <li>
            <i class="far fa-map-marked-alt"></i>
            6701 Democracy Blvd, Suite 300, USA
        </li>
        <li>
            <i class="far fa-phone"></i>
            0800-123456 (24/7 Support Line)
        </li>
        <li>
            <i class="far fa-envelope-open"></i>
            example@example.com
        </li>
    </ul>
</div>
<!-- tweets Widget -->
<div class="widget tweets-widget">
    <h5 class="widget-title">Recent tweets</h5>
    <ul>
        <li>
            <a href="#">
                <i class="fab fa-twitter"></i>
                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium...
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fab fa-twitter"></i>
                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium...
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fab fa-twitter"></i>
                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium...
            </a>
        </li>
    </ul>
</div>
<!-- Social Link -->
<div class="widget social-link">
    <h5 class="widget-title">Follow us</h5>
    <ul>
        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
        <li><a href="#"><i class="fab fa-behance"></i></a></li>
        <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
        <li><a href="#"><i class="fab fa-google"></i></a></li>
    </ul>
</div>